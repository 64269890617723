// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDXVuW3J6VL8Ytq8W4LeY5xlP41KMZ7xwA",
  authDomain: "gustave-42.firebaseapp.com",
  projectId: "gustave-42",
  storageBucket: "gustave-42.appspot.com",
  messagingSenderId: "289511079344",
  appId: "1:289511079344:web:e7b8ab7fb53c01336a6a75",
  measurementId: "G-BXY5X3F3LB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Functions
export const functions = getFunctions(app);

// Use this line for local development with Firebase emulators
// connectFunctionsEmulator(functions, "localhost", 5001);