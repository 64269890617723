// src/components/AddStepForm.js

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';


function AddStepForm({ dish, updateDish }) {
  const [open, setOpen] = useState(false);
  const [stepData, setStepData] = useState({
    name: '',
    type: '',
    duration: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
    setStepData({
      name: '',
      type: '',
      duration: '',
    });
  };

  const handleClose = () => {
    setOpen(false);
    setStepData({
      name: '',
      type: '',
      duration: '',
    });
  };

  const handleChange = (e) => {
    setStepData({ ...stepData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, type, duration } = stepData;

    if (!name || !type || !duration) {
      toast.error('Please fill in all fields.', {
        position: 'top-right',
        autoClose: 3000,
      });
      return;
    }

    const nextOrder = dish.steps.length;

    const newStep = {
      id: uuidv4(),
      name,
      type,
      duration: parseInt(duration, 10),
      order: nextOrder,
    };

    const updatedDish = {
      ...dish,
      steps: [...dish.steps, newStep],
    };

    updateDish(updatedDish);

    toast.success(`Step "${newStep.name}" added.`, {
      position: 'top-right',
      autoClose: 3000,
    });

    handleClose();
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Step
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Step</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Step Name"
              name="name"
              type="text"
              fullWidth
              value={stepData.name}
              onChange={handleChange}
            />
            <TextField
              select
              margin="dense"
              label="Step Type"
              name="type"
              fullWidth
              value={stepData.type}
              onChange={handleChange}
            >
              <MenuItem value="Preparation">Preparation</MenuItem>
              <MenuItem value="Cooking">Cooking</MenuItem>
              <MenuItem value="Resting">Resting</MenuItem>
            </TextField>
            <TextField
              margin="dense"
              label="Duration (minutes)"
              name="duration"
              type="number"
              fullWidth
              value={stepData.duration}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add Step
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

AddStepForm.propTypes = {
  dish: PropTypes.object.isRequired,
  updateDish: PropTypes.func.isRequired,
};

export default AddStepForm;
