// src/utils/notificationService.js
export const requestNotificationPermission = async () => {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
    return false;
  }
  
  let permission = await Notification.requestPermission();
  return permission === "granted";
};

export const scheduleNotification = (stepMessage, bodyMessage, time) => {
  if (!areNotificationsEnabled()) return;

  const now = new Date().getTime();
  const notificationTime = time.getTime();
  const delay = notificationTime - now;

  if (delay < 0) return; // Don't schedule if the time has passed

  setTimeout(() => {
    new Notification(stepMessage, {
      body: bodyMessage,
      icon: 'kitchen-timer/src/images/robo-chef.png', // Optional: Add an icon to the notification
    });
  }, delay);
};


export const areNotificationsEnabled = () => {
  return localStorage.getItem('notificationsEnabled') === 'true';
};