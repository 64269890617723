import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, IconButton, Collapse, Chip, TextField, Tooltip } from '@mui/material';
import StepItem from './StepItem';
import AddStepForm from './AddStepForm';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LinkIcon from '@mui/icons-material/Link'; // Import Link icon
import { Droppable, Draggable } from '@hello-pangea/dnd';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { scheduleNotification } from '../utils/notificationService';

function DishItem({ dish, updateDish, deleteDish, servingTime, dragHandleProps }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditingDishName, setIsEditingDishName] = useState(false);
  const [tempDishName, setTempDishName] = useState(dish.name);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const { startTime, endTime, stepsWithTimes, totalDuration } = useMemo(() => {
    if (!servingTime || dish.steps.length === 0) {
      return { startTime: null, endTime: null, stepsWithTimes: [], totalDuration: 0 };
    }

    let currentTime = new Date(servingTime.getTime());
    let totalDuration = 0;
    const stepsWithTimes = [...dish.steps].reverse().map(step => {
      const stepEndTime = new Date(currentTime.getTime());
      currentTime = new Date(currentTime.getTime() - step.duration * 60000);
      const stepStartTime = new Date(currentTime.getTime());
      totalDuration += step.duration;

      scheduleNotification(step.name, dish.name, stepStartTime);

      return {
        ...step,
        id: step.id || uuidv4(),
        startTime: stepStartTime,
        endTime: stepEndTime
      };
    }).reverse();

    const startTime = stepsWithTimes.length > 0 ? stepsWithTimes[0].startTime : null;
    const endTime = stepsWithTimes.length > 0 ? stepsWithTimes[stepsWithTimes.length - 1].endTime : null;

    return { startTime, endTime, stepsWithTimes, totalDuration };
  }, [dish, servingTime]);

  const handleEditDishName = () => {
    setIsEditingDishName(true);
    setTempDishName(dish.name);
  };

  const handleSaveDishName = () => {
    const updatedDish = { ...dish, name: tempDishName };
    updateDish(updatedDish);
    setIsEditingDishName(false);
  };

  const handleCancelDishNameEdit = () => {
    setIsEditingDishName(false);
    setTempDishName(dish.name);
  };

  const handleUrlClick = () => {
    if (dish.sourceUrl) {
      window.open(dish.sourceUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const updateStep = (updatedStep) => {
    const updatedSteps = stepsWithTimes.map((step) =>
      step.id === updatedStep.id ? { ...step, ...updatedStep } : step
    );
    const updatedDish = {
      ...dish,
      steps: updatedSteps,
    };
    updateDish(updatedDish);
    toast.success(`Step "${updatedStep.name}" updated in dish "${dish.name}".`, {
      position: 'top-right',
      autoClose: 3000,
    });
  };

  const deleteStep = (stepId) => {
    const stepToDelete = stepsWithTimes.find((step) => step.id === stepId);
    if (!stepToDelete) return;

    const updatedSteps = stepsWithTimes.filter((step) => step.id !== stepId);
    const updatedDish = {
      ...dish,
      steps: updatedSteps,
    };
    updateDish(updatedDish);
    toast.success(`Step "${stepToDelete.name}" deleted from dish "${dish.name}".`, {
      position: 'top-right',
      autoClose: 3000,
    });
  };

  const formatTime = (time) => {
    if (!time) return 'N/A';
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };

  const getDishStatus = () => {
    if (!startTime || !endTime) return 'Not Started';
    if (currentTime < startTime) return 'Upcoming';
    if (currentTime >= startTime && currentTime < endTime) return 'In Progress';
    return 'Completed';
  };

  return (
    <Box
      className="dish-item"
      p={1}
      mb={1}
      border={1}
      borderColor="divider"
      borderRadius={2}
      bgcolor="background.paper"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <IconButton {...dragHandleProps} size="small" sx={{ cursor: 'grab', mr: 1 }}>
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
          {isEditingDishName ? (
            <TextField
              value={tempDishName}
              onChange={(e) => setTempDishName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          ) : (
            <Typography variant="subtitle1" component="span" noWrap sx={{ flexGrow: 1 }}>
              {dish.name}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {dish.sourceUrl && (
            <Tooltip title={dish.sourceUrl}>
              <IconButton onClick={handleUrlClick} size="small">
                <LinkIcon />
              </IconButton>
            </Tooltip>
          )}
          {isEditingDishName ? (
            <>
              <IconButton onClick={handleSaveDishName} color="primary" size="small">
                <CheckIcon />
              </IconButton>
              <IconButton onClick={handleCancelDishNameEdit} color="secondary" size="small">
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={handleEditDishName} size="small">
              <EditIcon />
            </IconButton>
          )}
          <IconButton onClick={() => setIsExpanded(!isExpanded)} size="small">
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <IconButton onClick={() => deleteDish(dish.id)} color="error" size="small">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="caption">
          {formatTime(startTime)} - {formatTime(endTime)}
        </Typography>
        <Chip
          label={getDishStatus()}
          size="small"
          color={
            getDishStatus() === 'Completed'
              ? 'success'
              : getDishStatus() === 'In Progress'
              ? 'primary'
              : 'default'
          }
        />
        <Typography variant="caption">{formatDuration(totalDuration)}</Typography>
      </Box>

      <Box
        position="relative"
        height={8}
        mb={1}
        borderRadius={4}
        overflow="hidden"
        bgcolor="grey.300"
      >
        {stepsWithTimes.map((step, index) => {
          const stepWidth = (step.duration / totalDuration) * 100;
          let stepColor = 'grey.500';
          if (step.type === 'Preparation') stepColor = 'primary.main';
          if (step.type === 'Cooking') stepColor = 'secondary.main';
          if (step.type === 'Resting') stepColor = 'warning.main';

          return (
            <Box
              key={step.id || `step-${index}`}
              position="absolute"
              left={`${((step.startTime - startTime) / (totalDuration * 60000)) * 100}%`}
              width={`${stepWidth}%`}
              height="100%"
              bgcolor={stepColor}
            />
          );
        })}
      </Box>

      <Collapse in={isExpanded}>
        <Droppable droppableId={`steps-${dish.id}`} type="step" direction="vertical">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {stepsWithTimes
                .sort((a, b) => a.order - b.order)
                .map((step, index) => (
                  <Draggable key={step.id} draggableId={step.id} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <StepItem
                          step={step}
                          updateStep={updateStep}
                          deleteStep={deleteStep}
                          servingTime={servingTime}
                          dishStartTime={startTime}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <AddStepForm dish={dish} updateDish={updateDish} />
      </Collapse>
    </Box>
  );
}

DishItem.propTypes = {
  dish: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sourceUrl: PropTypes.string, // New prop for source URL
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        order: PropTypes.number.isRequired,
      })
    ).isRequired,
    order: PropTypes.number,
  }).isRequired,
  updateDish: PropTypes.func.isRequired,
  deleteDish: PropTypes.func.isRequired,
  servingTime: PropTypes.instanceOf(Date),
  dragHandleProps: PropTypes.object,
};

export default DishItem;
