// src/utils/toastUtils.js
import { toast } from 'react-toastify';

const TOAST_AUTO_CLOSE = 3000;

export const showToast = (type, message, options = {}) => {
  const toastId = options.toastId || message;
  
  if (!toast.isActive(toastId)) {
    toast[type](message, {
      toastId,
      position: 'top-right',
      autoClose: TOAST_AUTO_CLOSE,
      ...options,
    });
  }
};

export const showSuccessToast = (message, options = {}) => showToast('success', message, options);
export const showErrorToast = (message, options = {}) => showToast('error', message, options);
export const showInfoToast = (message, options = {}) => showToast('info', message, options);