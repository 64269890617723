import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { TextField, Button, Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText, LinearProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function SignUp() {
  const { signup } = useAuth();
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  // Password validation states
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({ score: 0, label: '', color: '' });

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(password));
  };

  useEffect(() => {
    setIsEmailValid(validateEmail(email));
  }, [email]);

  useEffect(() => {
    validatePassword(password);
    setPasswordsMatch(password === confirmPassword && password !== '');

    const requirementsMet = [isLengthValid, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length;
    
    if (requirementsMet < 3) {
      setPasswordStrength({ score: 20, label: 'Weak', color: 'error' });
    } else if (requirementsMet === 3 || requirementsMet === 4) {
      setPasswordStrength({ score: 60, label: 'Moderate', color: 'warning' });
    } else {
      setPasswordStrength({ score: 100, label: 'Strong', color: 'success' });
    }
  }, [password, confirmPassword, isLengthValid, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar]);

  const isPasswordValid = [isLengthValid, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length >= 3;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      setError('Please enter a valid email address.');
      return;
    }
    if (!isPasswordValid) {
      setError('Please ensure your password meets at least 3 requirements.');
      return;
    }
    if (!passwordsMatch) {
      setError('Passwords do not match');
      return;
    }
    try {
      setError('');
      await signup(email, password, firstName, lastName);
      navigate('/');
    } catch (err) {
      setError('Failed to create an account');
      console.error(err);
    }
  };

  const PasswordRequirement = ({ isMet, text }) => (
    <ListItem dense>
      <ListItemIcon sx={{ minWidth: '30px' }}>
        {isMet ? <CheckCircleOutlineIcon color="success" fontSize="small" /> : <ErrorOutlineIcon color="error" fontSize="small" />}
      </ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ variant: 'caption' }} />
    </ListItem>
  );

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Sign Up
        </Typography>
        {error && (
          <Typography color="error" variant="body2" gutterBottom>
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="First Name"
            type="text"
            required
            fullWidth
            margin="dense"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            size="small"
          />
          <TextField
            label="Last Name"
            type="text"
            required
            fullWidth
            margin="dense"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            size="small"
          />
          <TextField
            label="Email"
            type="email"
            required
            fullWidth
            margin="dense"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!isEmailValid && email !== ''}
            helperText={!isEmailValid && email !== '' ? "Please enter a valid email address" : ''}
            size="small"
          />
          <TextField
            label="Password"
            type="password"
            required
            fullWidth
            margin="dense"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            size="small"
          />
          <Box sx={{ mt: 0.5, mb: 1 }}>
            <Typography variant="caption" gutterBottom>
              Password strength: {passwordStrength.label}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={passwordStrength.score} 
              color={passwordStrength.color}
              sx={{ height: 6, borderRadius: 3 }}
            />
          </Box>
          <TextField
            label="Confirm Password"
            type="password"
            required
            fullWidth
            margin="dense"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!passwordsMatch && confirmPassword !== ''}
            helperText={!passwordsMatch && confirmPassword !== '' ? "Passwords don't match" : ''}
            size="small"
          />
          <Typography variant="caption" gutterBottom sx={{ mt: 1, display: 'block' }}>
            Password Requirements (meet at least 3):
          </Typography>
          <List dense disablePadding>
            <PasswordRequirement isMet={isLengthValid} text="At least 8 characters long" />
            <PasswordRequirement isMet={hasUpperCase} text="Contains an uppercase letter" />
            <PasswordRequirement isMet={hasLowerCase} text="Contains a lowercase letter" />
            <PasswordRequirement isMet={hasNumber} text="Contains a number" />
            <PasswordRequirement isMet={hasSpecialChar} text="Contains a special character" />
          </List>
          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            fullWidth 
            sx={{ mt: 1, mb: 1 }}
            disabled={!isEmailValid || !isPasswordValid || !passwordsMatch}
          >
            Sign Up
          </Button>
        </form>
        <Typography variant="body2" align="center">
          Already have an account? <Link to="/login">Log In</Link>
        </Typography>
      </Box>
    </Container>
  );
}


export default SignUp;