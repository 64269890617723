import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Grid, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { scheduleNotification } from '../utils/notificationService';  // Import the notification scheduler

function MealTimeline({ dishes, servingTime }) {
  const theme = useTheme();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Schedule notifications for each step
  useEffect(() => {
    if (dishes.length > 0 && servingTime) {
      dishes.forEach(dish => {
        const totalDuration = dish.steps.reduce((sum, step) => sum + step.duration, 0);
        let stepStartTime = new Date(servingTime.getTime() - totalDuration * 60000);  // Calculate step start time

        dish.steps.forEach(step => {
          // Schedule notification with proper format
          scheduleNotification(
            `It's time to start the ${step.type.toLowerCase()} of ${step.name}.`,
            `This should take ${step.duration} minutes.`,
            stepStartTime
          );
          stepStartTime = new Date(stepStartTime.getTime() + step.duration * 60000);  // Update start time for the next step
        });
      });
    }
  }, [dishes, servingTime]);

  const calculateDishTimes = (dish, servingTime) => {
    let endTime = new Date(servingTime);
    let totalDuration = 0;
    
    // Ensure valid durations are used (e.g., step.duration should not be NaN)
    const stepsWithTimes = dish.steps.map(step => {
      const stepDuration = isNaN(step.duration) ? 0 : step.duration; // Guard against NaN
      totalDuration += stepDuration;
      return { 
        ...step, 
        duration: stepDuration,
        id: step.id || uuidv4()
      };
    });
    
    const startTime = new Date(endTime.getTime() - totalDuration * 60000);
    return { startTime, endTime, stepsWithTimes };
  };

  const { mealStart, totalDuration, dishesWithTimes, currentTimePosition } = useMemo(() => {
    if (!servingTime || dishes.length === 0) {
      return { mealStart: null, totalDuration: 0, dishesWithTimes: [], currentTimePosition: null };
    }

    let mealStart = new Date(servingTime);
    let mealEnd = new Date(servingTime);

    const dishesWithTimes = dishes.map(dish => {
      const { startTime, endTime, stepsWithTimes } = calculateDishTimes(dish, servingTime);
      if (startTime < mealStart) mealStart = startTime;
      if (endTime > mealEnd) mealEnd = endTime;
      return { ...dish, startTime, endTime, stepsWithTimes };
    });

    const totalDuration = (mealEnd - mealStart) / 60000;  // in minutes

    const earliestStart = Math.min(...dishesWithTimes.map(dish => dish.startTime.getTime()));
    const latestEnd = Math.max(...dishesWithTimes.map(dish => dish.endTime.getTime()));

    let currentTimePosition = null;
    if (currentTime >= new Date(earliestStart) && currentTime <= new Date(latestEnd)) {
      currentTimePosition = ((currentTime - earliestStart) / (latestEnd - earliestStart)) * 100;
    }

    return { mealStart, totalDuration, dishesWithTimes, currentTimePosition };
  }, [dishes, servingTime, currentTime]);

  if (!mealStart || totalDuration === 0) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
      <Box sx={{ position: 'relative', width: '100%', mt: 2 }}>
        {currentTimePosition !== null && (
          <Box
            sx={{
              position: 'absolute',
              left: `calc(25% + ${currentTimePosition}% * 0.75)`,
              top: 10,
              bottom: -5,
              width: 2,
              backgroundColor: theme.palette.error.main,
              zIndex: 2,
            }}
          />
        )}
        {dishesWithTimes.map((dish) => {
          const dishStart = (dish.startTime - mealStart) / 60000;  // in minutes
          const dishDuration = (dish.endTime - dish.startTime) / 60000;  // in minutes

          return (
            <Grid container key={dish.id} spacing={2} alignItems="center" sx={{ mb: 1 }}>
              <Grid item xs={3}>
                <Typography 
                  variant="body2" 
                  align="right" 
                  sx={{ 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis',
                    pr: 2
                  }}
                >
                  {dish.name}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Box sx={{ height: '10px', position: 'relative' }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: `${(dishStart / totalDuration) * 100}%`,
                      width: `${(dishDuration / totalDuration) * 100}%`,
                      height: '100%',
                      display: 'flex',
                    }}
                  >
                    {dish.stepsWithTimes.map((step) => {
                      const stepWidth = (step.duration / dishDuration) * 100;
                      let stepColor = theme.palette.grey[300];
                      if (step.type === 'Preparation') stepColor = theme.palette.primary.main;
                      if (step.type === 'Cooking') stepColor = theme.palette.secondary.main;
                      if (step.type === 'Resting') stepColor = theme.palette.warning.main;

                      return (
                        <Box
                          key={step.id}
                          sx={{
                            width: `${stepWidth}%`,
                            height: '100%',
                            bgcolor: stepColor,
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
}

MealTimeline.propTypes = {
  dishes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string.isRequired,
          duration: PropTypes.number.isRequired,
          type: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  servingTime: PropTypes.instanceOf(Date),
};

export default MealTimeline;
