import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  IconButton, Typography, Grid, InputAdornment, Tooltip, Menu, MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ContentCopy } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import LanguageIcon from '@mui/icons-material/Language';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DishList from './DishList';
import ServingTimePicker from './ServingTimePicker';
import AddFromWebDialog from './AddFromWebDialog';
import MealBrowser from './MealBrowser';
import MealTimeline from './MealTimeline';
import WelcomeDialog from './WelcomeDialog';
import ConfirmDialog from './ConfirmDialog';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';
import { getIdToken } from 'firebase/auth';
import { auth } from '../firebase';
import { isMealChanged, saveMealToCloud, getMealFromCloud } from '../utils/mealService';



const TOAST_AUTO_CLOSE = 5000;
const DEFAULT_SERVING_TIME = new Date().setHours(19, 0, 0, 0); // 7:00 PM


const MealManagement = ({
  meal,
  setMeal,
  servingTime,
  setServingTime,
  addDish,
  updateDish,
  deleteDish,
  isWebDialogOpen,
  setIsWebDialogOpen,
  calculateFromNow,
  handleMealNameChange,
}) => {
  // State declarations
  const [isMealBrowserOpen, setIsMealBrowserOpen] = useState(false);
  const [isAddDishDialogOpen, setIsAddDishDialogOpen] = useState(false);
  const [newDishName, setNewDishName] = useState('');
  const [isEditingMealName, setIsEditingMealName] = useState(false);
  const [tempMealName, setTempMealName] = useState(meal.name);
  const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoadMealConfirmOpen, setIsLoadMealConfirmOpen] = useState(false);
  const [mealToLoad, setMealToLoad] = useState(null);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmSaveOpen, setIsConfirmSaveOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [isOverwriteConfirmOpen, setIsOverwriteConfirmOpen] = useState(false);
  const [mealToOverwrite, setMealToOverwrite] = useState(null);




  const buttonSx = {
    minWidth: '42px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .MuiButton-startIcon': {
      mr: { xs: 0, sm: 1 }
    },
    px: { xs: 1, sm: 2 },
    '& .MuiButton-endIcon': {
      display: 'none'
    },
    '& .button-text': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  };

  const isInitialMount = useRef(true);

  const handleCopyLink = () => {
    if (shareableLink) {
      navigator.clipboard.writeText(shareableLink)
        .then(() => {
          toast.success('Link copied to clipboard!');
        })
        .catch(err => {
          console.error('Error copying link:', err);
          toast.error('Failed to copy link');
        });
    } else {
      toast.error('No link available to copy');
    }
  };


  const handleSaveMeal = async (silent = false) => {
    if (!meal.id) {
      console.log("No meal ID, saving new meal...");
    } else {
      const needsUpdate = await isMealChanged(meal);
      if (!needsUpdate) {
        console.log("Meal is up-to-date, skipping save.");
        return meal.id; // No need to save if there are no changes
      }
    }
  
    try {
      const savedMealId = await saveMealToCloud(meal);
      console.log('Meal saved with ID:', savedMealId);
      const updatedMeal = { ...meal, id: savedMealId };
      setMeal(updatedMeal);
      if (!silent) {
        toast.success('Meal saved successfully!');
      }
      return savedMealId;
    } catch (error) {
      console.error('Error saving meal:', error);
      if (error.message === 'MEAL_NAME_EXISTS') {
        setMealToOverwrite(meal);
        setIsOverwriteConfirmOpen(true);
        return false;
      }
      toast.error(error.message);
      return false;
    }
  };
  

  const handleOverwriteConfirm = async () => {
    setIsOverwriteConfirmOpen(false);
    if (mealToOverwrite) {
      try {
        const savedMealId = await saveMealToCloud({ ...mealToOverwrite, id: undefined });
        console.log('Meal overwritten with ID:', savedMealId);
        const updatedMeal = { ...mealToOverwrite, id: savedMealId };
        setMeal(updatedMeal);
        toast.success('Meal overwritten successfully!');
        return savedMealId;
      } catch (error) {
        console.error('Error overwriting meal:', error);
        toast.error(error.message);
        return false;
      }
    }
  };


  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
      const storedMeal = JSON.parse(localStorage.getItem('meal'));

      if (!hasSeenWelcome && (!storedMeal || (storedMeal.name === 'My Meal' && storedMeal.dishes.length === 0))) {
        setIsWelcomeDialogOpen(true);
      }

      if (!servingTime) {
        setServingTime(new Date(DEFAULT_SERVING_TIME));
      }

      // Initial meal setup
      setMeal(prevMeal => ({
        ...prevMeal,
        dishes: prevMeal.dishes.map(dish => ({
          ...dish,
          id: dish.id || uuidv4(),
          steps: dish.steps.map(step => ({
            ...step,
            id: step.id || uuidv4()
          }))
        }))
      }));
    }
  }, [setMeal, servingTime, setServingTime]);

  useEffect(() => {
    // Check for updates on subsequent renders
    const needsUpdate = meal.dishes.some(dish =>
      !dish.id || dish.steps.some(step => !step.id)
    );

    if (needsUpdate) {
      setMeal(prevMeal => ({
        ...prevMeal,
        dishes: prevMeal.dishes.map(dish => ({
          ...dish,
          id: dish.id || uuidv4(),
          steps: dish.steps.map(step => ({
            ...step,
            id: step.id || uuidv4()
          }))
        }))
      }));
    }
  }, [meal, setMeal]);

  const handleCloseWelcomeDialog = () => {
    setIsWelcomeDialogOpen(false);
    localStorage.setItem('hasSeenWelcome', 'true');
  };

  const handleUpdateDish = useCallback((updatedDish) => {
    setMeal((prevMeal) => ({
      ...prevMeal,
      dishes: prevMeal.dishes.map((dish) =>
        dish.id === updatedDish.id ? updatedDish : dish
      ),
    }));
  }, [setMeal]);

  const handleSelectMeal = (selectedMeal) => {
    if (meal.dishes.length > 0) {
      setMealToLoad(selectedMeal);
      setIsLoadMealConfirmOpen(true);
    } else {
      loadMeal(selectedMeal);
    }
  };


  const handleAddDish = () => {
    if (newDishName.trim() === '') {
      toast.error('Dish name cannot be empty.', {
        position: 'top-right',
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }

    const newDish = {
      id: uuidv4(),
      name: newDishName,
      steps: [],
      order: meal.dishes.length,
    };
    addDish(newDish);
    setNewDishName('');
    setIsAddDishDialogOpen(false);
  };

  const handleEditMealName = () => {
    setIsEditingMealName(true);
    setTempMealName(meal.name);
  };

  const handleSaveMealName = () => {
    handleMealNameChange(tempMealName);
    setIsEditingMealName(false);
  };

  const handleCancelMealNameEdit = () => {
    setIsEditingMealName(false);
    setTempMealName(meal.name);
  };

  const handleDeleteMeal = () => {
    setIsConfirmDialogOpen(true);
  };

  const confirmDeleteMeal = () => {
    setMeal({
      id: uuidv4(),
      name: 'My Meal',
      dishes: [],
    });
    setServingTime(null);
    localStorage.removeItem('meal');
    localStorage.removeItem('servingTime');
    localStorage.removeItem('hasSeenWelcome');
    setIsConfirmDialogOpen(false);
    setIsWelcomeDialogOpen(true);
    toast.success('Meal deleted successfully!', {
      position: 'top-right',
      autoClose: TOAST_AUTO_CLOSE,
    });
  };

  const loadMeal = (selectedMeal) => {
    const newMeal = {
      ...selectedMeal,
      id: selectedMeal.id || uuidv4(), // Use the existing ID if it exists
      dishes: selectedMeal.dishes.map((dish, dishIndex) => ({
        ...dish,
        id: dish.id || uuidv4(),
        order: dish.order ?? dishIndex,
        steps: dish.steps.map((step, stepIndex) => ({
          ...step,
          id: step.id || uuidv4(),
          order: step.order ?? stepIndex,
        })),
      })),
    };
    setMeal(newMeal);
    setIsMealBrowserOpen(false);

    if (!servingTime) {
      setServingTime(new Date(DEFAULT_SERVING_TIME));
    }

    toast.success(`Meal "${newMeal.name}" imported!`, {
      position: 'top-right',
      autoClose: TOAST_AUTO_CLOSE,
    });
  };
  const handleShareMeal = async () => {
    try {
      setIsShareDialogOpen(true);
      setShareableLink('Generating link...');
  
      let mealToShare = meal;
      let needsSave = !meal.id || await isMealChanged(meal);
  
      if (needsSave) {
        toast.info('Saving meal before sharing...', { autoClose: 2000 });
        const savedMealId = await handleSaveMeal(true); // Ensure saveMeal doesn't create duplicates
        if (!savedMealId) {
          throw new Error('Failed to save meal before sharing');
        }
        mealToShare = await getMealFromCloud(savedMealId);
      } else {
        mealToShare = await getMealFromCloud(meal.id);
      }
  
      const shareResult = await performShare(mealToShare);
  
      if (shareResult) {
        setShareableLink(shareResult);
      }
    } catch (error) {
      console.error('Error sharing meal:', error);
      toast.error(`Failed to share meal: ${error.message}`);
      setIsShareDialogOpen(false);
    }
  };
  

  const performShare = async (mealToShare) => {
    const idToken = await getIdToken(auth.currentUser);
    const response = await fetch('/api/shareMeal', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
      body: JSON.stringify({ mealId: mealToShare.id }),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to generate sharing link');
    }
  
    const data = await response.json();
    return `${window.location.origin}/shared-meal/${data.shareId}`;
  };
  
    

  const handleConfirmSave = async () => {
    setIsConfirmSaveOpen(false);
    await handleSaveMeal();
    if (pendingAction === 'share') {
      await performShare();
    }
    setPendingAction(null);
  };
    

  return (
    <Box>
      {/* Header section */}
      <Grid container spacing={1} alignItems="center" mb={2}>
        <Grid item xs zeroMinWidth>
          <Box display="flex" alignItems="center">
            {isEditingMealName ? (
              <TextField
                value={tempMealName}
                onChange={(e) => setTempMealName(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear meal name"
                        onClick={() => setTempMealName('')}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography
                variant="h5"
                component="h1"
                noWrap
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {meal.name}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center">
            {isEditingMealName ? (
              <>
                <IconButton onClick={handleSaveMealName} color="primary" size="small">
                  <CheckIcon />
                </IconButton>
                <IconButton onClick={handleCancelMealNameEdit} color="secondary" size="small">
                  <CloseIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleEditMealName} size="small">
                <EditIcon />
              </IconButton>
            )}
            <IconButton onClick={handleMenuOpen} size="small">
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      
      {/* Menu (outside the Grid to avoid layout issues) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => { handleDeleteMeal(); handleMenuClose(); }}>
          <DeleteIcon sx={{ mr: 1 }} /> Delete Meal
        </MenuItem>
        <MenuItem onClick={() => { setIsMealBrowserOpen(true); handleMenuClose(); }}>
          <MenuBookIcon sx={{ mr: 1 }} /> Browse Saved Meals
        </MenuItem>
        <MenuItem onClick={() => { handleSaveMeal(); handleMenuClose(); }}>
          <SaveIcon sx={{ mr: 1 }} /> Save Meal
        </MenuItem>
        <MenuItem onClick={() => { handleShareMeal(); handleMenuClose(); }}>
          <ShareIcon sx={{ mr: 1 }} /> Share Meal
        </MenuItem>
      </Menu>
  
      {/* Content section */}
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={6} container justifyContent="flex-start">
          <Tooltip title="Calculate timings starting from now">
            <Button
              variant="contained"
              color="primary"
              startIcon={<HourglassEmptyIcon />}
              onClick={calculateFromNow}
              sx={{
                ...buttonSx,
                width: 'auto',
                maxWidth: 'max-content',
              }}
            >
              Start Now
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <ServingTimePicker
            servingTime={servingTime || new Date()}
            setServingTime={setServingTime}
          />
        </Grid>
      </Grid>
  
      {(servingTime || meal.dishes.length > 0) && (
        <MealTimeline
          dishes={meal.dishes}
          servingTime={servingTime || new Date()}
        />
      )}
  
      <DishList
        dishes={meal.dishes}
        updateDish={handleUpdateDish}
        deleteDish={deleteDish}
        servingTime={servingTime || new Date(DEFAULT_SERVING_TIME)}
      />
      
      <Box mt={2} display="flex" justifyContent="flex-start" alignItems="center">
        <Tooltip title="Add a new dish">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsAddDishDialogOpen(true)}
            sx={{ ...buttonSx, mr: 1 }}
          >
            Add
          </Button>
        </Tooltip>
        
        <Tooltip title="Add a dish from a web recipe">
          <Button
            variant="contained"
            color="success"
            startIcon={<LanguageIcon />}
            onClick={() => setIsWebDialogOpen(true)}
            sx={buttonSx}
          >
            Add from Web
          </Button>
        </Tooltip>
      </Box>
      <ConfirmDialog
        open={isOverwriteConfirmOpen}
        onClose={() => setIsOverwriteConfirmOpen(false)}
        onConfirm={handleOverwriteConfirm}
        title="Overwrite Existing Meal"
        content={`A meal with the name "${mealToOverwrite?.name}" already exists. Do you want to overwrite it?`}
      />
          <ConfirmDialog
      open={isOverwriteConfirmOpen}
      onClose={() => setIsOverwriteConfirmOpen(false)}
      onConfirm={handleOverwriteConfirm}
      title="Overwrite Existing Meal"
      content={`A meal with the name "${mealToOverwrite?.name}" already exists. Do you want to overwrite it?`}
    />
      <Dialog open={isAddDishDialogOpen} onClose={() => setIsAddDishDialogOpen(false)}>
        <DialogTitle>Add New Dish</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Dish Name"
            type="text"
            fullWidth
            value={newDishName}
            onChange={(e) => setNewDishName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDishDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddDish}>Add</Button>
        </DialogActions>
      </Dialog>
  
      <AddFromWebDialog
        open={isWebDialogOpen}
        onClose={() => setIsWebDialogOpen(false)}
        onAddDish={addDish}
      />
  
      <Dialog
        open={isMealBrowserOpen}
        onClose={() => setIsMealBrowserOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Browse Saved Meals</DialogTitle>
        <DialogContent>
          <MealBrowser onSelectMeal={handleSelectMeal} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsMealBrowserOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={isConfirmSaveOpen}
        onClose={() => setIsConfirmSaveOpen(false)}
        onConfirm={handleConfirmSave}
        title="Save Changes"
        content="The meal has unsaved changes. Do you want to save before continuing?"
      />
      <WelcomeDialog
        open={isWelcomeDialogOpen}
        onClose={handleCloseWelcomeDialog}
        onSetMealName={(newName) => {
          handleMealNameChange(newName);
          handleCloseWelcomeDialog();
        }}
        onAddFromWeb={() => {
          handleCloseWelcomeDialog();
          setIsWebDialogOpen(true);
        }}
        onBrowseSavedMeals={() => {
          handleCloseWelcomeDialog();
          setIsMealBrowserOpen(true);
        }}
      />
  
      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={confirmDeleteMeal}
        title="Delete Meal"
        content="Are you sure you want to delete this meal? This action cannot be undone."
      />
  
      <Dialog
        open={isLoadMealConfirmOpen}
        onClose={() => setIsLoadMealConfirmOpen(false)}
      >
        <DialogTitle>Replace Current Meal?</DialogTitle>
        <DialogContent>
          Are you sure you want to replace the current meal?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsLoadMealConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              loadMeal(mealToLoad);
              setIsLoadMealConfirmOpen(false);
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog 
  open={isShareDialogOpen} 
  onClose={() => setIsShareDialogOpen(false)}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>Share Your Meal</DialogTitle>
  <DialogContent>
    <Box sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
      <Typography 
        variant="body2" // Changed from body1 to body2 for smaller text
        sx={{ 
          wordBreak: 'break-all', 
          flexGrow: 1,
          mr: 1,
          fontFamily: 'monospace',
          fontSize: '0.75rem', // Added explicit font size
          bgcolor: (theme) => theme.palette.grey[100],
          p: 1.5, // Reduced padding slightly
          borderRadius: 1,
          overflowWrap: 'break-word',
          maxWidth: 'calc(100% - 48px)', // Ensure text doesn't overlap with the icon button
        }}
      >
        {shareableLink || ''}
      </Typography>
      <Tooltip title="Copy link">
        <IconButton onClick={handleCopyLink} color="primary" size="small">
          <ContentCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsShareDialogOpen(false)} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>
    </Box>
  );
};

MealManagement.propTypes = {
  meal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dishes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            order: PropTypes.number,
          })
        ).isRequired,
        order: PropTypes.number,
        startTime: PropTypes.any,
        endTime: PropTypes.any,
      })
    ).isRequired,
  }).isRequired,
  setMeal: PropTypes.func.isRequired,
  servingTime: PropTypes.instanceOf(Date),
  setServingTime: PropTypes.func.isRequired,
  addDish: PropTypes.func.isRequired,
  updateDish: PropTypes.func.isRequired,
  deleteDish: PropTypes.func.isRequired,
  isWebDialogOpen: PropTypes.bool.isRequired,
  setIsWebDialogOpen: PropTypes.func.isRequired,
  calculateFromNow: PropTypes.func.isRequired,
  handleMealNameChange: PropTypes.func.isRequired,
};

export default MealManagement;