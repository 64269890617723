import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { alpha, useTheme } from '@mui/material/styles';

const AddFromWebDialog = ({ open, onClose, onAddDish }) => {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dishData, setDishData] = useState(null);
  const [manualInstructions, setManualInstructions] = useState('');
  const [isManualMode, setIsManualMode] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      setUrl('');
      setDishData(null);
      setManualInstructions('');
      setIsManualMode(false);
    }
  }, [open]);

  const fetchRecipeFromUrl = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/recipe?url=${encodeURIComponent(url)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      if (!data.success || !data.recipe) {
        throw new Error('Invalid response format');
      }
      setDishData({ ...data.recipe, sourceUrl: url });  // Add source URL to dish data
      toast.success('Recipe fetched successfully!', { autoClose: 3000 });
    } catch (error) {
      console.error('Fetch error:', error);
      toast.error(`Failed to fetch recipe: ${error.message}`, { autoClose: 5000 });
    } finally {
      setIsLoading(false);
    }
  };

  const processManualInstructions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/process-manual', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ instructions: manualInstructions }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      setDishData(data.recipe);
      toast.success('Instructions processed successfully!', { autoClose: 3000 });
    } catch (error) {
      console.error('Processing error:', error);
      toast.error(`Failed to process instructions: ${error.message}`, { autoClose: 5000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = () => {
    if (dishData) {
      onAddDish(dishData);  // Pass the dish data, including the source URL, to be saved in Firebase
      onClose();
    }
  };

  const handleStepChange = (index, field, value) => {
    const updatedSteps = dishData.steps.map((step, i) => 
      i === index ? { ...step, [field]: field === 'duration' ? parseInt(value, 10) : value } : step
    );
    setDishData({ ...dishData, steps: updatedSteps });
  };

  const handleStepDelete = (index) => {
    const updatedSteps = dishData.steps.filter((_, i) => i !== index);
    setDishData({ ...dishData, steps: updatedSteps });
  };

  const getStepColor = (type) => {
    switch (type) {
      case 'Preparation':
        return alpha(theme.palette.primary.main, 0.1);
      case 'Cooking':
        return alpha(theme.palette.secondary.main, 0.1);
      case 'Resting':
        return alpha(theme.palette.warning.main, 0.1);
      default:
        return alpha(theme.palette.grey[300], 0.1);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ typography: 'h6', pb: 1 }}>Add Dish from Web</DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        {!dishData && (
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  label={isManualMode ? "Recipe Instructions" : "Recipe URL"}
                  value={isManualMode ? manualInstructions : url}
                  onChange={(e) => isManualMode ? setManualInstructions(e.target.value) : setUrl(e.target.value)}
                  fullWidth
                  margin="dense"
                  autoFocus
                  size="small"
                  multiline={isManualMode}
                  rows={isManualMode ? 6 : 1}
                  disabled={isLoading}
                  InputProps={{
                    style: isManualMode ? { fontSize: '0.875rem' } : {}
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Tooltip title="You can manually paste in the recipe instructions here" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setIsManualMode(!isManualMode)}
                  size="small"
                  startIcon={isManualMode ? <LanguageIcon /> : <EditIcon />}
                >
                  {isManualMode ? 'Switch to URL' : 'Manual Input'}
                </Button>
              </Tooltip>
              {isManualMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={processManualInstructions}
                  disabled={!manualInstructions || isLoading}
                  size="small"
                >
                  {isLoading ? <CircularProgress size={20} /> : 'Process Instructions'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={fetchRecipeFromUrl}
                  disabled={!url || isLoading}
                  size="small"
                  startIcon={<SearchIcon />}
                  sx={{ 
                    width: 'auto',
                    minWidth: 'max-content',
                    px: 2
                  }}
                >
                  {isLoading ? <CircularProgress size={20} /> : 'Fetch Recipe'}
                </Button>
              )}
            </Box>
          </Box>
        )}

        {dishData && (
          <Box>
            <TextField
              label="Dish Name"
              value={dishData.name}
              onChange={(e) => setDishData({ ...dishData, name: e.target.value })}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            />
            {/* Display the source URL if available */}
            {dishData.sourceUrl && (
              <TextField
                label="Source URL"
                value={dishData.sourceUrl}
                fullWidth
                margin="dense"
                variant="outlined"
                size="small"
                disabled
              />
            )}
            <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
              <Grid item xs={6}>
                <Typography variant="body2">Step Name</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">Type</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">Duration (mins)</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">Action</Typography>
              </Grid>
            </Grid>
            {dishData.steps.map((step, index) => (
              <Grid container spacing={1} key={index} sx={{ mt: 0.5, backgroundColor: getStepColor(step.type), borderRadius: 1, alignItems: 'center' }}>
                <Grid item xs={6}>
                  <TextField
                    value={step.name}
                    onChange={(e) => handleStepChange(index, 'name', e.target.value)}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth size="small">
                    <Select
                      value={step.type}
                      onChange={(e) => handleStepChange(index, 'type', e.target.value)}
                    >
                      <MenuItem value="Preparation">Preparation</MenuItem>
                      <MenuItem value="Cooking">Cooking</MenuItem>
                      <MenuItem value="Resting">Resting</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    type="number"
                    value={step.duration}
                    onChange={(e) => handleStepChange(index, 'duration', e.target.value)}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleStepDelete(index)} color="error" size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" size="small">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" disabled={!dishData} size="small">
          Add Dish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddFromWebDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddDish: PropTypes.func.isRequired,
};

export default AddFromWebDialog;
