import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { useAuth } from './AuthContext';
import PropTypes from 'prop-types';

const WelcomeDialog = ({ open, onClose, onSetMealName, onAddFromWeb, onBrowseSavedMeals }) => {
  const [mealName, setMealName] = useState('');
  const { currentUser } = useAuth();

  const handleSubmit = () => {
    if (mealName.trim()) {
      onSetMealName(mealName);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Welcome to Gustave</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Welcome to Gustave, your friendly AI meal timer. Please enter a name for your meal, add a meal from the web, or load from your saved meals.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="Meal Name"
          type="text"
          fullWidth
          value={mealName}
          onChange={(e) => setMealName(e.target.value)}
        />
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={onAddFromWeb} fullWidth>
            Add Meal from Web
          </Button>
        </Box>
        {currentUser && (
          <Box mt={2}>
            <Button variant="contained" color="secondary" onClick={onBrowseSavedMeals} fullWidth>
              Browse Saved Meals
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!mealName.trim()}>
          Create Meal
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WelcomeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetMealName: PropTypes.func.isRequired,
  onAddFromWeb: PropTypes.func.isRequired,
  onBrowseSavedMeals: PropTypes.func.isRequired,
};

export default WelcomeDialog;