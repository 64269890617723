import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import PropTypes from 'prop-types';

function EditStepDialog({ open, onClose, step, onSubmit }) {
  const [editedStep, setEditedStep] = useState({ ...step });

  useEffect(() => {
    setEditedStep({ ...step });
  }, [step]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedStep((prev) => ({
      ...prev,
      [name]: name === 'duration' ? parseInt(value, 10) : value,
    }));
  };

  const handleSubmit = () => {
    onSubmit(editedStep);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Step</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Step Name"
          type="text"
          fullWidth
          value={editedStep.name}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="step-type-label">Step Type</InputLabel>
          <Select
            labelId="step-type-label"
            name="type"
            value={editedStep.type}
            onChange={handleChange}
            label="Step Type"
          >
            <MenuItem value="Preparation">Preparation</MenuItem>
            <MenuItem value="Cooking">Cooking</MenuItem>
            <MenuItem value="Resting">Resting</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          name="duration"
          label="Duration (minutes)"
          type="number"
          fullWidth
          value={editedStep.duration}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditStepDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditStepDialog;