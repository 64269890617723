import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Chip,
  IconButton,
} from '@mui/material';
import EditStepDialog from './EditStepDialog';
import { format, isValid } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { alpha, useTheme } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import HotelIcon from '@mui/icons-material/Hotel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';

function StepItem({ step, updateStep, deleteStep, servingTime, dishStartTime, dragHandleProps }) {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditSubmit = (updatedStep) => {
    updateStep(updatedStep);
    setIsEditDialogOpen(false);
  };

  // Calculate step start and end times
  const stepStartTime = step.startTime;
  const stepEndTime = step.endTime;

  // Determine step status
  const getStepStatus = () => {
    if (!isValid(stepStartTime) || !isValid(stepEndTime)) return 'Not Started';
    if (currentTime < stepStartTime) return 'Upcoming';
    if (currentTime >= stepStartTime && currentTime < stepEndTime) return 'Active';
    return 'Complete';
  };

  const status = getStepStatus();

  // Map status to colors
  const statusColor = {
    'Not Started': 'default',
    'Upcoming': 'info',
    'Active': 'primary',
    'Complete': 'success',
  };

  // Get background color based on step type
  const getBackgroundColor = (type) => {
    let color;
    if (type === 'Preparation') {
      color = alpha(theme.palette.primary.main, 0.2);
    } else if (type === 'Cooking') {
      color = alpha(theme.palette.secondary.main, 0.2);
    } else if (type === 'Resting') {
      color = alpha(theme.palette.warning.main, 0.2);
    } else {
      color = alpha(theme.palette.grey[300], 0.2);
    }
    return color;
  };

  // Get icon based on step type
  const getIconByType = (type) => {
    if (type === 'Preparation') return <BuildIcon fontSize="small" />;
    if (type === 'Cooking') return <RestaurantMenuIcon fontSize="small" />;
    if (type === 'Resting') return <HotelIcon fontSize="small" />;
    return <HelpOutlineIcon fontSize="small" />;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={1}
      p={1}
      border={1}
      borderColor="divider"
      borderRadius={2}
      bgcolor={getBackgroundColor(step.type)}
    >
      {/* Step Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" flexGrow={1}>
          {/* Drag Handle */}
          <IconButton
            {...dragHandleProps}
            size="small"
            sx={{ cursor: 'grab', mr: 1 }}
          >
            <DragIndicatorIcon fontSize="small" />
          </IconButton>

          {/* Icon for Step Type */}
          <Box sx={{ mr: 1 }}>
            {getIconByType(step.type)}
          </Box>

          {/* Step Description */}
          <Box flexGrow={1}>
            <Typography variant="body2" sx={{ fontSize: '0.95rem', fontWeight: 'bold' }}>
              {step.name}
            </Typography>
          </Box>

          {/* Step Type */}
          <Box sx={{ mx: 1 }}>
            <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
              {step.type}
            </Typography>
          </Box>

          {/* Step Duration */}
          <Box>
            <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
              {step.duration} min
            </Typography>
          </Box>
        </Box>

        {/* Status Chip and Action Buttons */}
        <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
          <Chip
            label={status}
            color={statusColor[status]}
            sx={{ mr: 1, fontSize: '0.65rem', height: '20px' }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={handleEditClick}
            size="small"
            sx={{ minWidth: 0, padding: 0 }}
          >
            <EditIcon fontSize="small" />
          </Button>
          <Button
            variant="text"
            color="error"
            onClick={() => deleteStep(step.id)}
            size="small"
            sx={{ minWidth: 0, padding: 0 }}
          >
            <DeleteIcon fontSize="small" />
          </Button>
        </Box>
      </Box>

      {/* Start and End Times */}
      <Box display="flex" justifyContent="space-between" mt={0.5}>
        <Typography
          variant="caption"
          color="textSecondary"
        >
          Start: {isValid(stepStartTime) ? format(stepStartTime, 'HH:mm') : 'N/A'}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
        >
          End: {isValid(stepEndTime) ? format(stepEndTime, 'HH:mm') : 'N/A'}
        </Typography>
      </Box>

      {/* Edit Dialog */}
      <EditStepDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        step={step}
        onSubmit={handleEditSubmit}
      />
    </Box>
  );
}

StepItem.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    startOffset: PropTypes.number,
    order: PropTypes.number,
  }).isRequired,
  updateStep: PropTypes.func.isRequired,
  deleteStep: PropTypes.func.isRequired,
  servingTime: PropTypes.instanceOf(Date),
  dishStartTime: PropTypes.instanceOf(Date),
  dragHandleProps: PropTypes.object,
};

export default StepItem;