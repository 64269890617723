import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import DishItem from './DishItem';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function DishList({ dishes, updateDish, deleteDish, servingTime }) {
  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
      <Droppable droppableId="dishes" type="dish" direction="vertical">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {dishes
              .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
              .map((dish, index) => (
                <Draggable
                  key={dish.id || `dish-${index}`}
                  draggableId={dish.id || `dish-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: 8 // Add some space between dishes
                      }}
                    >
                      <DishItem
                        dish={dish}
                        updateDish={updateDish}
                        deleteDish={deleteDish}
                        servingTime={servingTime}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
}

DishList.propTypes = {
  dishes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          duration: PropTypes.number.isRequired,
          order: PropTypes.number,
        })
      ).isRequired,
      order: PropTypes.number,
      startTime: PropTypes.any,
      endTime: PropTypes.any,
    })
  ).isRequired,
  updateDish: PropTypes.func.isRequired,
  deleteDish: PropTypes.func.isRequired,
  servingTime: PropTypes.any,
};

export default DishList;