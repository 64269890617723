import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { 
  Box, Typography, Button, IconButton, Menu, MenuItem, ListItemIcon, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Drawer, List,
  AppBar, Toolbar, useMediaQuery, Divider, Switch
} from '@mui/material';
import { 
  Brightness4, Brightness7, AccountCircle, Home, Notifications, Info,
  Menu as MenuIcon, ExitToApp, Person
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import roboChefLight from '../images/robo-chef.png';
import roboChefDark from '../images/robo-chef-white.png';
import { requestNotificationPermission } from '../utils/notificationService';

function Header({ toggleTheme, meal, setMeal, titleFontSize = '2.5rem' }) {
  const theme = useTheme();
  const { currentUser, logout, updateUserProfile } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isAboutDialogOpen, setIsAboutDialogOpen] = useState(false);  // New state for About dialog
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getFirstName = (displayName) => {
    if (!displayName) return '';
    return displayName.split(' ')[0];
  };

  useEffect(() => {
    const storedNotificationSetting = localStorage.getItem('notificationsEnabled');
    setNotificationsEnabled(storedNotificationSetting === 'true');

    if (currentUser && currentUser.displayName) {
      const [firstNameFromDb, lastNameFromDb] = currentUser.displayName.split(' ');
      setFirstName(firstNameFromDb || '');
      setLastName(lastNameFromDb || '');
    }
  }, [currentUser]);

  const handleNotificationToggle = async () => {
    const newSetting = !notificationsEnabled;
    if (newSetting) {
      const permission = await requestNotificationPermission();
      if (!permission) {
        toast.error('Notification permission denied');
        return;
      }
    }
    setNotificationsEnabled(newSetting);
    localStorage.setItem('notificationsEnabled', newSetting.toString());
    toast.success(`Notifications ${newSetting ? 'enabled' : 'disabled'}`);
  };

  const handleMenu = (event) => {
    if (isMobile) {
      setDrawerOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDrawerOpen(false);
  };

  const handleProfileOpen = () => {
    setIsProfileDialogOpen(true);
    handleClose();
  };

  const handleProfileClose = () => {
    setIsProfileDialogOpen(false);
  };

  const handleAboutOpen = () => {
    setIsAboutDialogOpen(true);
    handleClose();
  };

  const handleAboutClose = () => {
    setIsAboutDialogOpen(false);
  };

  const handleProfileUpdate = async () => {
    try {
      await updateUserProfile(currentUser, {
        displayName: `${firstName} ${lastName}`,
      });
      toast.success('Profile updated successfully');
      handleProfileClose();
    } catch (err) {
      toast.error('Failed to update profile');
      console.error(err);
    }
  };

  const handleHome = () => {
    navigate('/');
    handleClose();
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
      toast.success('Logged out successfully!');
    } catch (err) {
      console.error('Failed to log out', err);
      toast.error('Failed to log out. Please try again.');
    }
    handleClose();
  };

  const menuItems = [
    { text: 'Home', icon: <Home />, onClick: handleHome },
    { text: 'Profile', icon: <Person />, onClick: handleProfileOpen },
    { 
      text: 'Notifications', 
      icon: <Notifications />, 
      onClick: handleNotificationToggle,
      switch: {
        checked: notificationsEnabled,
        onChange: handleNotificationToggle,
      },
    },
    { 
      text: 'About', 
      icon: <Info />, 
      onClick: handleAboutOpen  // Open the About dialog
    },
  ];

  const renderMenuItems = () => (
    <Box>
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={item.onClick} sx={{ '&:hover': { backgroundColor: theme.palette.action.hover } }}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          {item.switch && (
            <Switch
              edge="end"
              onChange={item.switch.onChange}
              checked={item.switch.checked}
            />
          )}
        </MenuItem>
          ))}
        
        <Divider />
    <MenuItem onClick={handleLogout} sx={{ color: theme.palette.error.main, '&:hover': { backgroundColor: theme.palette.action.hover } }}>
      <ListItemIcon>
        <ExitToApp color="error" />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </MenuItem>
  </Box>
  );

  return (
    <Box>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
            <Box 
              display="flex" 
              alignItems="center" 
              flexGrow={1}
              sx={{
                height: '64px', // Adjust this value to match your AppBar height
              }}
            >
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                <img 
                  src={theme.palette.mode === 'dark' ? roboChefDark : roboChefLight}
                  alt="Robo Chef"
                  style={{ marginRight: '8px', width: '40px', height: '40px' }}
                />
                <Typography 
                  variant="h6" 
                  component="h1" 
                  color="text.primary" 
                  noWrap
                  className="gustave-title"
                  sx={{
                    fontFamily: '"Boecklins Universe", cursive',
                    fontSize: titleFontSize,
                    fontWeight: 'normal',
                    letterSpacing: '0.05em',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                    transition: 'font-size 0.3s ease',
                    transform: 'translateY(4px)', // Adjust this value to move the text down
                    display: 'inline-block', // Ensures transform works as expected
                    lineHeight: 1, // Adjust line height to fine-tune vertical positioning
                    '&:hover': {
                      fontSize: `calc(${titleFontSize} * 1.08)`, // 8% increase on hover
                    },
                    '@media (max-width:600px)': {
                      fontSize: `calc(${titleFontSize} * 0.8)`, // 20% decrease on mobile
                    },
                  }}
                >
                  Gustave
                </Typography>
              </Link>
            </Box>
    
            <Box display="flex" alignItems="center">
            <IconButton color="inherit" onClick={toggleTheme} aria-label="Toggle theme">
              {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
            {currentUser ? (
              <>
                <Typography variant="body2" sx={{ mr: 1, display: { xs: 'none', sm: 'block' } }}>
                  {getFirstName(currentUser.displayName) || currentUser.email}
                </Typography>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {isMobile ? <MenuIcon /> : <AccountCircle />}
                </IconButton>
              </>
            ) : (
              <Button color="inherit" onClick={() => navigate('/login')}>
                Login
              </Button>
            )}
          </Box>

          {isMobile ? (
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <List sx={{ width: 250 }}>{renderMenuItems()}</List>
            </Drawer>
          ) : (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: 2,
                  marginTop: 1,
                  minWidth: 180,
                },
              }}
            >
              {renderMenuItems()}
            </Menu>
          )}
        </Toolbar>
      </AppBar>
    
        {/* Add spacing below the AppBar */}
        <Box pb={2} />
    
        <Dialog open={isProfileDialogOpen} onClose={handleProfileClose}>
          <DialogTitle>Update Profile</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="First Name"
              type="text"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Last Name"
              type="text"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleProfileClose}>Cancel</Button>
            <Button onClick={handleProfileUpdate}>Update</Button>
          </DialogActions>
        </Dialog>

        {/* About Dialog */}
        <Dialog open={isAboutDialogOpen} onClose={handleAboutClose}>
          <DialogTitle>About Gustave</DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              Welcome to Gustave, the kitchen timer with a sprinkle of magic! Designed to help you juggle multiple dishes so they’re all perfectly ready at the same time, Gustave is your ultimate sous-chef, ensuring your culinary creations come together like a symphony.
            </Typography>
            <Typography gutterBottom>
              What’s most extraordinary? Gustave was created entirely by AI – not a single line of code was written by a human (including me, Gustave’s so-called "creator"). The entire app was born from the sheer brilliance of today’s AI capabilities, with tools like ChatGPT and Claude leading the way on this awe-inspiring journey.
            </Typography>
            <Typography gutterBottom>
              Whether you're a home cook or a seasoned chef, Gustave makes meal preparation smooth and stress-free. If you’d like to learn more or get in touch, feel free to reach out at: gustave@gustave.cooking.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAboutClose}>Close</Button>
          </DialogActions>
        </Dialog>

      </Box>
    );
}

Header.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
  meal: PropTypes.object,
  setMeal: PropTypes.func,
  titleFontSize: PropTypes.string,
};

export default Header;
