// src/theme.js
import { createTheme } from '@mui/material/styles';


// Define an array of font options
const fontOptions = [
  '"Lora", serif',
  '"Roboto", sans-serif',
  '"Playfair Display", serif',
  '"Open Sans", sans-serif',
  '"Montserrat", sans-serif',
  '"Boecklins Universe", sans-serif',
];

// Function to get the current font (you can implement logic to switch between fonts)
const getCurrentFont = () => {
  // For now, we'll return the first option (Lora)
  return fontOptions[1];
};


/**
 * Generates a MUI theme based on the provided mode.
 * @param {string} mode - The current theme mode ('light' or 'dark').
 * @returns {object} - The generated MUI theme.
 */
const getTheme = (mode) => createTheme({
  palette: {
    mode, // 'light' or 'dark' determines overall theme
    primary: {
      main: '#5E81AC', // Nord10 - Blue
      contrastText: '#2E3440', // Text color on primary
    },
    secondary: {
      main: '#BF616A', // Nord11 - Red
      contrastText: '#2E3440',
    },
    success: {
      main: '#A3BE8C', // Nord14 - Green
      contrastText: '#2E3440',
    },
    warning: {
      main: '#EBCB8B', // Nord13 - Yellow
      contrastText: '#2E3440',
    },
    error: {
      main: '#BF616A', // Reuse Nord11 - Red
      contrastText: '#ECEFF4',
    },
    info: {
      main: '#3498db', // Info color
      contrastText: '#ECEFF4',
    },
    background: {
      default: mode === 'light' ? '#ECEFF4' : '#2E3440',
      paper: mode === 'light' ? '#D8DEE9' : '#3B4252',
    },
    text: {
      primary: mode === 'light' ? '#2E3440' : '#ECEFF4',
      secondary: mode === 'light' ? '#4C566A' : '#D8DEE9',
    },
    divider: mode === 'light' ? '#D8DEE9' : '#4C566A',
  },
  typography: {
    fontFamily: getCurrentFont(),
    h4: {
      fontWeight: 600, // Semi-bold for h4 headings
    },
    h6: {
      fontWeight: 500, // Medium weight for h6 headings
    },
    body1: {
      fontSize: '1rem', // Base font size for body text
    },
    body2: {
      fontSize: '0.875rem', // Slightly smaller font for secondary text
    },
    caption: {
      fontSize: '0.75rem', // Smaller font for captions and hints
      color: mode === 'light' ? '#4C566A' : '#D8DEE9',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable uppercase transformation for all buttons
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem', // Match chip font size to body2 for consistency
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h6', component: 'h1' },
          style: {
            fontFamily: '"Boecklins Universe", sans-serif',
          },
        },
      ],

      styleOverrides: {
        h6: {
          // Remove any fontFamily override here if it exists
        },
      },
        },
    // Add more component customizations as needed
  },
});

export default getTheme;
