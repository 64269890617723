import React, { useState, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Container } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Routes, Route } from 'react-router-dom';
import { DragDropContext } from '@hello-pangea/dnd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getTheme from './theme';
import Header from './components/Header';
import Login from './components/Login';
import SignUp from './components/SignUp';
import PrivateRoute from './components/PrivateRoute';
import MealManagement from './components/MealManagement';
import SharedMealView from './components/SharedMealView';
import PasswordReset from './components/PasswordReset'; // Import the PasswordReset component
import { v4 as uuidv4 } from 'uuid';
import useToast from './hooks/useToast';
import { requestNotificationPermission } from './utils/notificationService';

const DEFAULT_MEAL_NAME = 'My Meal';

function App() {
  const [meal, setMeal] = useState({
    id: uuidv4(),
    name: DEFAULT_MEAL_NAME,
    dishes: [],
  });

  const [servingTime, setServingTime] = useState(null);
  const [mode, setMode] = useState('light');
  const [isWebDialogOpen, setIsWebDialogOpen] = useState(false);
  const [isMealNameCustom, setIsMealNameCustom] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const font = new FontFace('Boecklins Universe', 'url(/fonts/BoecklinsUniverse.ttf)');
    font.load().then((loadedFont) => {
      document.fonts.add(loadedFont);
      document.body.style.fontFamily = 'Boecklins Universe, sans-serif';
    }).catch((error) => {
      console.error('Font loading failed:', error);
    });
  }, []);

  useEffect(() => {
    try {
      const storedMeal = JSON.parse(localStorage.getItem('meal'));
      const storedServingTime = localStorage.getItem('servingTime');
      const storedMode = localStorage.getItem('themeMode');
      const storedIsMealNameCustom = JSON.parse(localStorage.getItem('isMealNameCustom'));

      if (storedMeal) setMeal(storedMeal);
      if (storedServingTime) setServingTime(new Date(storedServingTime));
      if (storedMode) setMode(storedMode);
      if (typeof storedIsMealNameCustom === 'boolean') setIsMealNameCustom(storedIsMealNameCustom);
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
    }
  }, []);

  useEffect(() => {
    if (meal) {
      localStorage.setItem('meal', JSON.stringify(meal));
    }
  }, [meal]);

  useEffect(() => {
    if (servingTime) {
      localStorage.setItem('servingTime', servingTime.toISOString());
    }
  }, [servingTime]);

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
    if (mode === 'dark') {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
    } else {
      document.body.classList.add('light-mode');
      document.body.classList.remove('dark-mode');
    }
  }, [mode]);

  useEffect(() => {
    localStorage.setItem('isMealNameCustom', JSON.stringify(isMealNameCustom));
  }, [isMealNameCustom]);

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  const theme = useMemo(() => getTheme(mode), [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const addDish = (newDish) => {
    setMeal((prevMeal) => ({
      ...prevMeal,
      dishes: [...prevMeal.dishes, { ...newDish, id: uuidv4(), order: prevMeal.dishes.length }],
    }));
    toast.success(`Dish "${newDish.name}" added to the meal.`, { toastId: `add-dish-${newDish.id}` });
  };

  const updateDish = (updatedDish) => {
    setMeal((prevMeal) => ({
      ...prevMeal,
      dishes: prevMeal.dishes.map((dish) =>
        dish.id === updatedDish.id ? { ...updatedDish } : dish
      ),
    }));
    toast.success(`Dish "${updatedDish.name}" updated.`, { toastId: `update-dish-${updatedDish.id}` });
  };

  const deleteDish = (dishId) => {
    const updatedDishes = meal.dishes.filter((dish) => dish.id !== dishId);
    setMeal({ ...meal, dishes: updatedDishes });
    toast.success(`Dish deleted.`);

    if (updatedDishes.length === 0 && !isMealNameCustom) {
      setMeal((prevMeal) => ({
        ...prevMeal,
        name: DEFAULT_MEAL_NAME,
      }));
      toast.info(`All dishes deleted. Meal name reset to default.`, { toastId: 'reset-name' });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    if (type === 'dish') {
      const reorderedDishes = Array.from(meal.dishes);
      const [movedDish] = reorderedDishes.splice(source.index, 1);
      reorderedDishes.splice(destination.index, 0, movedDish);

      const updatedDishes = reorderedDishes.map((dish, index) => ({
        ...dish,
        order: index,
      }));

      setMeal({ ...meal, dishes: updatedDishes });
      toast.info(`Dish order updated.`);

    } else if (type === 'step') {
      const dishId = source.droppableId.replace('steps-', '');
      const dish = meal.dishes.find((d) => d.id === dishId);

      if (!dish) return;

      const reorderedSteps = Array.from(dish.steps);
      const [movedStep] = reorderedSteps.splice(source.index, 1);
      reorderedSteps.splice(destination.index, 0, movedStep);

      const updatedSteps = reorderedSteps.map((step, index) => ({
        ...step,
        order: index,
      }));

      const updatedDish = { ...dish, steps: updatedSteps };

      const updatedDishes = meal.dishes.map((d) =>
        d.id === dishId ? updatedDish : d
      );

      setMeal({ ...meal, dishes: updatedDishes });
      toast.info(`Step order updated.`);
    }
  };

  const calculateFromNow = () => {
    if (meal.dishes.length === 0) {
      toast.error('No dishes to calculate timings for.', { toastId: 'calculate-error' });
      return;
    }

    const now = new Date();
    const dishDurations = meal.dishes.map((dish) => {
      return dish.steps.reduce((acc, step) => acc + (step.duration || 0), 0);
    });

    if (dishDurations.every((duration) => duration === 0)) {
      toast.error('Total duration of steps is zero.', { toastId: 'calculate-zero-error' });
      return;
    }

    const maxDuration = Math.max(...dishDurations);
    const earliestServingTime = new Date(now.getTime() + maxDuration * 60000);

    setServingTime(earliestServingTime);
    toast.success('Serving time calculated starting from now.');
  };

  const handleMealNameChange = (newName) => {
    setMeal((prevMeal) => ({
      ...prevMeal,
      name: newName,
    }));
    setIsMealNameCustom(true);
    toast.info('Meal name customized.', { toastId: 'name-customized' });
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Container
          maxWidth="md"
          sx={{ paddingTop: '20px', paddingBottom: '20px' }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Header toggleTheme={toggleTheme} meal={meal} setMeal={setMeal} />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<PasswordReset />} /> {/* Add this route */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <MealManagement
                      meal={meal}
                      setMeal={setMeal}
                      servingTime={servingTime}
                      setServingTime={setServingTime}
                      addDish={addDish}
                      updateDish={updateDish}
                      deleteDish={deleteDish}
                      isWebDialogOpen={isWebDialogOpen}
                      setIsWebDialogOpen={setIsWebDialogOpen}
                      calculateFromNow={calculateFromNow}
                      handleMealNameChange={handleMealNameChange}
                    />
                  </PrivateRoute>
                }
              />
              <Route path="/shared-meal/:shareId" element={<SharedMealView />} />
            </Routes>
          </DragDropContext>
          <ToastContainer />
        </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
