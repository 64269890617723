// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();

  if (currentUser === undefined) {
    // Still loading the auth state, can show a loading spinner
    return <div>Loading...</div>;
  }

  // Redirect to login if not authenticated
  return currentUser ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
