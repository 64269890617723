// src/components/SharedMealView.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Button, Typography, CircularProgress, Box, List, ListItem, ListItemText, 
   Paper, TextField, IconButton, Tooltip, Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { saveMealToCloud } from '../utils/mealService';

function SharedMealView() {
  const { shareId } = useParams();
  const [sharedMeal, setSharedMeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingName, setEditingName] = useState(false);
  const [mealName, setMealName] = useState('');
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchSharedMeal = async () => {
      try {
        const sharedMealDoc = await getDoc(doc(db, 'sharedMeals', shareId));
        if (sharedMealDoc.exists()) {
          const mealData = sharedMealDoc.data();
          setSharedMeal(mealData);
          setMealName(mealData.name);
        } else {
          toast.error('Shared meal not found or has expired.');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching shared meal:', error);
        toast.error('Failed to load shared meal');
        setLoading(false);
      }
    };

    fetchSharedMeal();
  }, [shareId, db]);

  
  const getTotalDishTime = (dish) => {
    return dish.steps.reduce((total, step) => total + step.duration, 0);
  };

  const getStepColor = (type) => {
    switch (type) {
      case 'Preparation':
        return 'primary.light';
      case 'Cooking':
        return 'secondary.light';
      case 'Resting':
        return 'warning.light';
      default:
        return 'grey.300';
    }
  };

  
  const handleImportMeal = async () => {
    if (!auth.currentUser) {
      toast.error('You must be logged in to import a meal.');
      return;
    }

    try {
      const { id, shareId, createdAt, expiresAt, ...mealToImport } = sharedMeal;
      mealToImport.name = mealName; // Use the potentially edited meal name
      await saveMealToCloud(mealToImport);
      toast.success('Meal imported successfully!');
    } catch (error) {
      console.error('Error importing meal:', error);
      if (error.message === 'MEAL_NAME_EXISTS') {
        toast.error('A meal with that name already exists. Please choose a different meal name.');
        setEditingName(true); // Automatically open the name editing field
      } else {
        toast.error('Failed to import meal. Please try again.');
      }
    }
  };


  const handleEditName = () => {
    setEditingName(true);
  };

  const handleSaveName = () => {
    setEditingName(false);
    // You might want to add validation here
    if (mealName.trim() === '') {
      setMealName(sharedMeal.name); // Reset to original name if empty
      toast.error('Meal name cannot be empty');
    }
  };

  const handleCancelEdit = () => {
    setEditingName(false);
    setMealName(sharedMeal.name); // Reset to original name
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!sharedMeal) {
    return <Typography>Shared meal not found or has expired.</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 1 }}>
      <Box display="flex" alignItems="center" mb={1}>
        {editingName ? (
          <>
            <TextField
              value={mealName}
              onChange={(e) => setMealName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
            <IconButton onClick={handleSaveName} color="primary" size="small">
              <CheckIcon />
            </IconButton>
            <IconButton onClick={handleCancelEdit} color="secondary" size="small">
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Typography variant="h5" sx={{ flexGrow: 1 }}>{mealName}</Typography>
            <Tooltip title="Edit meal name">
              <IconButton onClick={handleEditName} size="small">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      <Button onClick={handleImportMeal} variant="contained" color="primary" sx={{ mb: 1 }}>
        Import Meal
      </Button>
      {sharedMeal.dishes && sharedMeal.dishes.map((dish, index) => (
        <Paper key={dish.id || index} elevation={3} sx={{ mb: 1, p: 1 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs>
              <Typography variant="subtitle1">{dish.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                {getTotalDishTime(dish)} min
              </Typography>
            </Grid>
          </Grid>
          <List dense>
            {dish.steps && dish.steps.map((step, stepIndex) => (
              <React.Fragment key={step.id || stepIndex}>
                <ListItem 
                  alignItems="flex-start"
                  sx={{ 
                    bgcolor: getStepColor(step.type),
                    borderRadius: 1,
                    mb: 0.5
                  }}
                >
                  <ListItemText
                    primary={`${step.name}`}
                    secondary={
                      <Typography component="span" variant="body2" color="text.primary">
                        {step.type} - {step.duration} min
                      </Typography>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ))}
    </Box>
  );
}


export default SharedMealView;