import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence // <- Enable persistent sessions across tabs
} from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Enable persistent login
  useEffect(() => {
    const enablePersistence = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
      } catch (error) {
        console.error('Error enabling persistence:', error);
      }
    };
    enablePersistence();
  }, []);

  // Signup function
  const signup = async (email, password, firstName, lastName) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredential.user, {
        displayName: `${firstName} ${lastName}`,
      });
      return userCredential.user;
    } catch (error) {
      console.error('Signup error:', error);
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('The email address is already in use by another account.');
      } else {
        throw new Error('Failed to create an account. Please try again.');
      }
    }
  };

  // Login function
  const login = async (email, password) => {
    try {
      // Ensure persistence is set before logging in
      await setPersistence(auth, browserLocalPersistence);
      return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Login error:', error);
      if (error.code === 'auth/wrong-password') {
        throw new Error('Incorrect password. Please try again.');
      } else if (error.code === 'auth/user-not-found') {
        throw new Error('No account found with this email. Please sign up first.');
      } else {
        throw new Error('Failed to log in. Please check your credentials and try again.');
      }
    }
  };

  // Password reset function
  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return 'Password reset email sent successfully. Please check your inbox.';
    } catch (error) {
      console.error('Password reset error:', error);
      if (error.code === 'auth/user-not-found') {
        // Do not reveal that the user does not exist for security reasons
        throw new Error('If an account with this email exists, you will receive a password reset email.');
      } else if (error.code === 'auth/invalid-email') {
        throw new Error('Please enter a valid email address.');
      } else {
        throw new Error('Failed to send password reset email. Please try again later.');
      }
    }
  };

  // Logout function
  const logout = () => {
    return signOut(auth);
  };

  // Update user profile function
  const updateUserProfile = (user, data) => {
    return updateProfile(user, data);
  };

  // Monitor auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe; // Cleanup listener on component unmount
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    updateUserProfile,
    resetPassword, // Expose resetPassword to the rest of the app
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Only render children once the auth state has been checked */}
    </AuthContext.Provider>
  );
}
