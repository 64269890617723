// src/hooks/useToast.js
import { useCallback } from 'react';
import { showSuccessToast, showErrorToast, showInfoToast } from '../utils/toastUtils';

const useToast = () => {
  const success = useCallback((message, options) => {
    showSuccessToast(message, options);
  }, []);

  const error = useCallback((message, options) => {
    showErrorToast(message, options);
  }, []);

  const info = useCallback((message, options) => {
    showInfoToast(message, options);
  }, []);

  return { success, error, info };
};

export default useToast;