import { db, auth } from '../firebase';
import { 
  collection, 
  getDocs, 
  setDoc,
  updateDoc, 
  deleteDoc, 
  doc,
  where,
  addDoc, 
  query, 
  orderBy, 
  getDoc
} from 'firebase/firestore';

const USERS_COLLECTION = 'users';
const MEALS_SUBCOLLECTION = 'meals';

export const fetchMealsFromCloud = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  const mealsRef = collection(db, USERS_COLLECTION, user.uid, MEALS_SUBCOLLECTION);
  const q = query(mealsRef, orderBy('savedAt', 'desc'));
  const snapshot = await getDocs(q);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const isMealChanged = async (meal) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  if (!meal.id) return true; // New meal, needs saving

  const mealRef = doc(db, USERS_COLLECTION, user.uid, MEALS_SUBCOLLECTION, meal.id);
  const mealDoc = await getDoc(mealRef);
  
  if (!mealDoc.exists()) return true; // Meal doesn't exist, needs saving

  const storedMeal = mealDoc.data();
  
  // Only compare important fields (e.g., name, dishes, steps), ignore metadata like 'id' and 'savedAt'
  const relevantMealFields = {
    name: meal.name,
    dishes: meal.dishes.map(dish => ({
      name: dish.name,
      steps: dish.steps.map(step => ({
        name: step.name,
        type: step.type,
        duration: step.duration
      }))
    }))
  };

  const relevantStoredMealFields = {
    name: storedMeal.name,
    dishes: storedMeal.dishes.map(dish => ({
      name: dish.name,
      steps: dish.steps.map(step => ({
        name: step.name,
        type: step.type,
        duration: step.duration
      }))
    }))
  };

  // Compare only the relevant fields
  return JSON.stringify(relevantMealFields) !== JSON.stringify(relevantStoredMealFields);
};



export const saveMealToCloud = async (meal) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  const mealsRef = collection(db, 'users', user.uid, 'meals');
  const mealWithMetadata = {
    ...meal,
    userId: user.uid,
    savedAt: new Date().toISOString(),
  };

  if (meal.id) {
    // Update existing meal
    await setDoc(doc(mealsRef, meal.id), mealWithMetadata, { merge: true });
    return meal.id;
  } else {
    // Check for existing meal with the same name
    const q = query(mealsRef, where("name", "==", meal.name));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      throw new Error('MEAL_NAME_EXISTS');
    }

    // Create new meal
    const newMealRef = await addDoc(mealsRef, mealWithMetadata);
    return newMealRef.id;
  }
};

export const updateMealNameInCloud = async (mealId, newName) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  const mealRef = doc(db, USERS_COLLECTION, user.uid, MEALS_SUBCOLLECTION, mealId);
  await updateDoc(mealRef, { 
    name: newName,
    updatedAt: new Date().toISOString(),
  });
};

export const deleteMealFromCloud = async (mealId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  const mealRef = doc(db, USERS_COLLECTION, user.uid, MEALS_SUBCOLLECTION, mealId);
  await deleteDoc(mealRef);
};

export const getMealFromCloud = async (mealId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  if (!mealId) throw new Error('Meal ID is required');

  const mealRef = doc(db, 'users', user.uid, 'meals', mealId);
  const mealDoc = await getDoc(mealRef);

  if (!mealDoc.exists()) {
    throw new Error('Meal not found');
  }

  return { id: mealDoc.id, ...mealDoc.data() };
};