import React, { useState, useEffect, useMemo } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function ServingTimePicker({ servingTime, setServingTime }) {
  const defaultTime = useMemo(() => {
    const time = new Date();
    time.setHours(19, 0, 0, 0); // Set default to 7:00 PM
    return time;
  }, []);

  const [open, setOpen] = useState(false);
  const [tempTime, setTempTime] = useState(servingTime || defaultTime);

  const formatTime = (date) => {
    if (!date) return '';
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const [inputValue, setInputValue] = useState(formatTime(servingTime || defaultTime));

  useEffect(() => {
    setInputValue(formatTime(servingTime || defaultTime));
  }, [servingTime, defaultTime]);

  const handleOpen = () => {
    setOpen(true);
    setTempTime(servingTime || defaultTime);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setServingTime(tempTime);
    setInputValue(formatTime(tempTime));
    handleClose();
  };

  const parseTime = (timeString) => {
    const [hoursStr, minutesStr] = timeString.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    if (
      Number.isInteger(hours) &&
      Number.isInteger(minutes) &&
      hours >= 0 &&
      hours <= 23 &&
      minutes >= 0 &&
      minutes <= 59
    ) {
      const newTime = new Date();
      newTime.setHours(hours);
      newTime.setMinutes(minutes);
      newTime.setSeconds(0);
      newTime.setMilliseconds(0);
      return newTime;
    }
    return null;
  };

  const handleTextFieldChange = (e) => {
    const inputTime = e.target.value;
    setInputValue(inputTime);
    
    const parsedTime = parseTime(inputTime);
    if (parsedTime) {
      setServingTime(parsedTime);
      setTempTime(parsedTime);
    }
  };

  const handleBlur = () => {
    const parsedTime = parseTime(inputValue);
    if (parsedTime) {
      setServingTime(parsedTime);
      setTempTime(parsedTime);
    } else {
      setInputValue(formatTime(servingTime || defaultTime));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ width: '130px' }}>
        <TextField
          label="Serving Time"
          value={inputValue}
          onChange={handleTextFieldChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleOpen}>
                  <AccessTimeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          size="small"
        />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <TimeClock
            value={tempTime}
            onChange={(newValue) => setTempTime(newValue)}
            ampm={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAccept} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}

export default ServingTimePicker;