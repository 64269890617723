import React, { useEffect, useState } from 'react';
import { fetchMealsFromCloud, deleteMealFromCloud, updateMealNameInCloud } from '../utils/mealService';
import { 
  List, 
  ListItem, 
  ListItemText, 
  CircularProgress, 
  Typography, 
  IconButton, 
  TextField,
  Box
} from '@mui/material';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';

function MealBrowser({ onSelectMeal }) {
  const [meals, setMeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editingMealId, setEditingMealId] = useState(null);
  const [editedMealName, setEditedMealName] = useState('');
  const theme = useTheme();

  useEffect(() => {
    loadMeals();
  }, []);

  const loadMeals = async () => {
    try {
      setLoading(true);
      const cloudMeals = await fetchMealsFromCloud();
      setMeals(cloudMeals);
    } catch (error) {
      console.error('Error fetching meals: ', error);
      setError('Failed to fetch meals. Please try again later.');
      toast.error('Failed to fetch meals: ' + error.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMeal = async (mealId) => {
    try {
      await deleteMealFromCloud(mealId);
      setMeals(meals.filter(meal => meal.id !== mealId));
      toast.success('Meal deleted successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error deleting meal: ', error);
      toast.error(`Failed to delete meal: ${error.message}`, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  const handleEditMeal = (meal) => {
    setEditingMealId(meal.id);
    setEditedMealName(meal.name);
  };

  const handleSaveEdit = async (mealId) => {
    try {
      await updateMealNameInCloud(mealId, editedMealName);
      setMeals(meals.map(meal => 
        meal.id === mealId ? { ...meal, name: editedMealName } : meal
      ));
      setEditingMealId(null);
      toast.success('Meal name updated successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error updating meal name: ', error);
      toast.error(`Failed to update meal name: ${error.message}`, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (meals.length === 0) {
    return <Typography>No saved meals found.</Typography>;
  }

  return (
    <List>
      {meals.map(meal => (
        <ListItem
          key={meal.id}
          secondaryAction={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {editingMealId === meal.id ? (
                <>
                  <IconButton edge="end" aria-label="save" onClick={() => handleSaveEdit(meal.id)} sx={{ color: theme.palette.success.main }}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="cancel" onClick={() => setEditingMealId(null)} sx={{ color: theme.palette.error.main }}>
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditMeal(meal)} sx={{ color: theme.palette.primary.main }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteMeal(meal.id)} sx={{ color: theme.palette.error.main }}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </Box>
          }
        >
          {editingMealId === meal.id ? (
            <TextField
              value={editedMealName}
              onChange={(e) => setEditedMealName(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
            />
          ) : (
            <ListItemText
              primary={meal.name}
              secondary={format(new Date(meal.savedAt), 'MMM d, yyyy HH:mm')}
              onClick={() => onSelectMeal(meal)}
              sx={{ cursor: 'pointer' }}
            />
          )}
        </ListItem>
      ))}
    </List>
  );
}

export default MealBrowser;